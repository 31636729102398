.detail-artikel {
  background-image: url("../../images/background-other-page.webp");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  /* height: 350vh; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-bottom: 5rem;
}

.detail-artikel-container {
  margin-top: 6rem;
  margin-bottom: 20rem;
  width: 80vw;
  overflow: hidden;
  color: white;
  position: relative;
  z-index: 1;
  /* background-color: white; */
}

.detail-artikel-bradcrumb {
  font-size: 0.947rem;
  margin: 1rem 0;
}

.detail-artikel-title h3 {
  font-size: 1.842rem;
  font-weight: 700;
  /* margin: 1rem 0; */
  text-transform: capitalize;
}

.jumlah-dibaca {
  font-size: 0.789rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-style: italic;
  font-weight: 600;
}

.waktu-artikel {
  font-size: 0.789rem;
  margin-bottom: 1rem;
}

.detail-artikel-gambar {
  width: 100%;
  height: 42.684rem;
  overflow: hidden;
  border-radius: 0.526rem;
  background-color: white;
}

.detail-artikel-gambar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.detail-artikel-deskripsi p {
  font-size: 1.158rem;
  line-height: 1.7rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.detail-artikel-deskripsi p span {
  display: block;
  font-weight: 700;
}

.artikel-terkait {
  margin-top: -10rem;
}

/* ADS */
.ads-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.2rem 0;
  overflow: hidden;
  padding: 1rem 0;
}

/* Split Halaman */
.split__pages {
  margin: 2rem 0;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.split__title {
  margin-right: 2rem;
}

.split__numbers {
  display: flex;
}

.wrapper__nomor {
  background: white;
  border-radius: 100%;
  color: var(--warna-hijau);
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  transition: 0.2s;
}

.wrapper__nomor:hover,
.active {
  background: var(--warna-kuning);
  cursor: pointer;
}

/* Share Buttons */
.share__buttons {
  margin: 2rem 0;
  display: flex;
  align-items: center;
}

.share__title {
  margin-right: 2rem;
  font-size: 1rem;
  font-weight: bold;
  color: var(--warna-kuning);
  font-style: italic;
}

.share__icons {
  display: flex;
}

.share__icon {
  margin-right: 1rem;
  border-radius: 0.2rem;
  overflow: hidden;
}

/* SETUP MOBILE */
@media only screen and (max-width: 768px) {
  .detail-artikel {
    background-image: url("../../images/background-other-page-mobile.png");
  }

  .detail-artikel-container {
    width: 90vw;
  }

  .detail-artikel-bradcrumb {
    font-size: 0.786rem;
    margin: 0.5rem 0;
  }

  .detail-artikel-title h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .detail-artikel-gambar {
    height: auto;
  }

  .detail-artikel-deskripsi p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 0.7rem;
  }

  .ads-wrapper .ads-kontents-wrapper {
    width: 60%;
    height: auto;
  }

  .ads-kontents-wrapper .nama-ads {
    padding: 0.5rem 0;
  }

  .ads-kontents-wrapper .nama-ads h5 {
    font-size: 0.8rem;
  }

  .tidak-ditemukan-wrapper {
    height: 20vh;
    font-size: 1rem;
  }

  .split__pages {
    margin: 1rem 0;
    font-size: 0.85rem;
  }
}
